// 共通情報

export const common = {
  path_name : {
    news : "/news/",
    about : "/about/",
    company : "/company/",
    business : "/business/",
    recruit : "/recruit/"
  }
}