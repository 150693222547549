/* eslint lines-around-directive: ["error", "always"] */

'use client';

import Script from 'next/script';

const GoogleAnalytics = ({ ga_id }: { ga_id: string }) => (
  <>
    <Script strategy={'afterInteractive'} src={`https://www.googletagmanager.com/gtag/js?id=${ga_id}`} />
    <Script
      id={'gtag-init'}
      strategy={'afterInteractive'}
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${ga_id}');    
           `,
      }}
    />
  </>
);
export default GoogleAnalytics;
