'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { common } from '@/app/consts/common';

function Header() {
  const [isOpen, setOpen] = useState(false);
  const [isSetClass, setClass] = useState('');

  useEffect(() => {
    window.addEventListener('resize', () => {
      setClass('');
    });
  }, []);

  const handleMenuOpen = () => {
    setOpen(!isOpen);
    setClass('');
  };

  const handleMenuClose = () => {
    setOpen(false);
    setClass('[transition:right_0.5s]');
  };

  const path = usePathname() ?? '/';
  const navLinkClassname: string =
    'text-base font-medium [transition:all_0.4s_ease-in-out] items-center flex justify-start md:justify-around font-medium h-full p-6 md:p-2 lg:px-4 lg:py-2 last:mr-2 hover:text-primary';
  const navLinkActiveClassname: string =
    ' text-primary ml-6 md:border-b-[6px] md:ml-0 border-l-[6px] md:border-l-0 border-primary';
  const navLinkDefaultClassname: string =
    ' text-other01 ml-6 md:border-b-[6px] md:ml-0 border-l-[6px] md:border-l-0 border-white';
  const isNewsPath = (checkPath: string) =>
    path.indexOf(checkPath) !== -1
      ? classNames(navLinkClassname, navLinkActiveClassname)
      : classNames(navLinkClassname, navLinkDefaultClassname);

  const pathActive = (checkPath: string) =>
    path === checkPath
      ? classNames(navLinkClassname, navLinkActiveClassname)
      : classNames(navLinkClassname, navLinkDefaultClassname);

  return (
    // <header className="l-header">
    <header className="fixed top-0 z-50 flex h-[70px] w-full items-center bg-white px-4 py-0 shadow-header md:px-10">
      <Link href="/" className="z-10">
        <Image
          src="/images/logo.svg"
          width={64}
          height={64}
          alt="plmin.usロゴ"
          className="block h-8 min-h-[32px] w-[108px]"
        />
      </Link>
      <ul
        id="l-headerMenu"
        className={`fixed top-0 ml-auto flex h-full w-full flex-col items-center bg-white md:relative md:w-fit md:flex-row
          ${
            isOpen
              ? 'right-0 [transition:right_0.5s] md:left-0'
              : `-right-full md:inset-x-0 ${isSetClass}`
          }`}
      >
        <li className="w-full pr-4 text-right md:hidden md:h-full md:w-auto">
          <button
            type="button"
            className="h-[70px] w-[70px]"
            onClick={handleMenuClose}
          >
            <span
              className="relative left-2/4 block h-[30px]
              w-[30px] pr-6 before:absolute before:top-2/4 before:h-[30px] before:w-1
              before:-translate-x-1/2 before:-translate-y-1/2 before:rotate-45 before:bg-txt05 after:absolute after:top-2/4
              after:h-[30px] after:w-1 after:-translate-x-1/2
              after:-translate-y-1/2 after:-rotate-45 after:bg-txt05
            "
            />
          </button>
        </li>
        <li className="h-auto w-full md:h-full md:w-auto">
          <Link
            href={{ pathname: common.path_name.news }}
            className={isNewsPath(common.path_name.news)}
            onClick={handleMenuClose}
          >
            お知らせ
          </Link>
        </li>
        <li className="h-auto w-full md:h-full md:w-auto">
          <Link
            href={{ pathname: common.path_name.about }}
            className={pathActive(common.path_name.about)}
            onClick={handleMenuClose}
          >
            私たちについて
          </Link>
        </li>
        <li className="h-auto w-full md:h-full md:w-auto">
          <Link
            href={{ pathname: common.path_name.company }}
            className={pathActive(common.path_name.company)}
            onClick={handleMenuClose}
          >
            会社案内
          </Link>
        </li>
        <li className="h-auto w-full md:h-full md:w-auto">
          <Link
            href={{ pathname: common.path_name.business }}
            className={pathActive(common.path_name.business)}
            onClick={handleMenuClose}
          >
            事業内容
          </Link>
        </li>
        <li className="h-auto w-full md:h-full md:w-auto">
          <Link
            href={{ pathname: common.path_name.recruit }}
            className={pathActive(common.path_name.recruit)}
            onClick={handleMenuClose}
          >
            採用情報
          </Link>
        </li>
        <li className="h-auto w-full text-center md:h-full md:w-auto">
          <button type="button" className="button-s button-primary mt-[15px] ">
            <Link
              href={{ pathname: '/contact/' }}
              className="text-sm"
              onClick={handleMenuClose}
            >
              問い合わせ
            </Link>
          </button>
        </li>
      </ul>
      <button
        type="button"
        className="ml-auto flex h-8 w-8 items-center justify-center md:hidden"
        onClick={handleMenuOpen}
      >
        {/* 3点リーダ */}
        <div>
          <span className="mb-1 block h-1 w-1 bg-txt05" />
          <span className="mb-1 block h-1 w-1 bg-txt05" />
          <span className="block h-1 w-1 bg-txt05" />
        </div>
      </button>
    </header>
  );
}

export default Header;
